.footer {
    background-color: $grey;
    padding: 50px 0;

    @include below (1024px) {
        padding: 50px 20px;
    }

    @include below ($m) {
        padding: 50px 0px;
    }
    &-inner {
        max-width: 1200px;
        margin: 0 auto; 
        &-firstrow {
            max-width: 740px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 60px;

            @include below ($m) {
                width: 90%; 
                margin: 0 auto;
                padding-bottom: 60px;
            }

            @include below (500px) {
                flex-direction: column;
                margin-bottom: 50px;
                padding-bottom: 0px;
            }

        }
        &-secondrow {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include below ($m) {
                width: 90%; 
                margin: 0 auto;
            }

            div {
                width: 33%;

                @include below (500px) {
                    width: 100%;
                    text-align: center;

                    img {
                        float: none !important;
                    }
                }

                &.align-c {
                    text-align: center;
                }

                &.align-r {
                    text-align: right;

                    @include below ($s) {
                        text-align: center;
                    }
                }
            }

            @include below (500px) {
                flex-direction: column;

                div {
                    img {
                        margin-bottom: 35px;
                    }
                }     
            }
        }
    }
    &-contact {
        @include below ($s) {
            text-align: center;
            margin-bottom: 20px;
        }
        a {
            display: block; 
            font-family: $arial;
            color: $black;
            font-weight: 400;
            line-height: 27px; 

            @include below ($s) {
                line-height: 24px;
            }
        }

        p {
            @include below ($s) {
                font-size: 17px;
            } 
        }
    }
    &-download-link {
        display: flex;
        align-items: center;
        color: $black;

        img {
            margin-right: 10px;
        }
    }
}

.top-anchor {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: block;
    width: 60px;
    height: 60px;
    background-color: $orange;
    background-image: url(../img/top-anchor.svg);
    background-position: center center;
    background-repeat: no-repeat;
    transition: opacity .2s ease-in-out;
    opacity: 0;
    z-index: 100;
    pointer-events: none;
    &.visible {
        opacity: 1;
        pointer-events: auto;
    }
    @include below(920px) {
        right: 10px;
        bottom: 10px;
        width: 40px;
        height: 40px;
        background-size: 10px 10px;
    }
}

.float-r {
    float: right;
}
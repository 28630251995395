.main-menu {
    padding: 25px 0 20px;

    @include below ($m) {
       min-height: 70px;
    }
    @include below ($s) {
        min-height: 60px;
     }
    &-inner {
        max-width: 1195px; 
        width: 100%; 
        display: flex; 
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;

        span {
            font-family: $league; 
            font-size: 50px; 
            color: $white;
            text-transform: uppercase;

            @include below ($m) {
                font-size: 29px;
            }

            @include below ($s) {
                font-size: 25px; 
            }
        }
    }
}

.menu-a {
    font-weight: 400;
    font-size: 0; 
    width: 33%;

    @include below ($m) {
        width: 100%; 
        text-align: center;
    }
}

.menu-a-spec {
    @include below ($m) {
        display: none;
    }
}

.img-jamt {
    @include below ($m) {
    width: 40%;
    }
}

.img-region {
    float: right; 
    @include below ($m) {
    width: 70%;
    float: none;
    display: none;
    }
}

.menu-menu-1-container {
    background-color: $darkgreen;
    @include below ($m) {
        display: none;
    }

}
.menu {
    display: flex;
    max-width: 1200px; 
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;

    li {
        margin-bottom: 0px;
        text-align: center;
        position: relative;
        height: 72px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        width: 100%;
        justify-content: center;

        &:after {
            content: '';
            width: 1px;
            background-color: $white;
            height: 100%; 
            position: absolute; 
            right: 0; 
            top: 0;
        }
        a {
            color: rgba(255, 255, 255, 0.7);
            // width: 100%;
            font-size: 15px;
            transition: all .2s ease-in-out; 
            max-height: 35px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 130px;
            white-space: nowrap;
            text-transform: uppercase;

            &:hover {
                color: $white;
            }

            span {
                font-weight: 400;
            }
        }
        &:before {
            display: none;

        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }
    }
}

.current-menu-item {
    a {
        color: $white !important;
        position: relative;

        &:after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: #FFF;
            position: absolute;
            bottom: -3px;
            left: 50%;
            transform: translateX(-50%);

        }

        &:before {
            content: '';
            position: absolute;
            width:100%;
            height: 1px; 
            background: white;
            left: 0;
            bottom:0;
        }
    }
}

.main-logo {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    text-align: center; 

    @include below ($m) {
        width: 60%;
        height: auto !important;
    }
    @include below ($s) {
        width: 80% !important;
        height: auto !important;
    }

    img {
        @include below ($s) {
        width: 87% !important;
        height: auto !important;
        }
    }
}
.sidebar {
    width: 30%;
    float: right;
    color: $blue;
    position: relative;

    @include below(920px) {
        display: none;
    }
}

.sidebar__inner {
    width: 100%;
    height: auto;
    float: right;
    max-width: 300px;
    margin-left: 5%;
    margin-right: 20px;
    position: relative;
    //transition: all 1s ease-in-out;

    @include below (1024px) {
        margin-right: 0;
    }

    &--sticky {
        position: fixed;
        z-index: 1;
        top: 1%;
        //transition: all 1s ease-in-out;
    }

    @include below(1024px) {
        margin-left: 3%;
    }
}

.sidebar__title {
    font-family: $league !important;
    text-transform: uppercase;
    font-size: 22px !important;
    color: #C6E0E4;
    background: $darkgreen;
    font-weight: 700 !important;
}

.sidebar__links li {
    width: 100%;
    display: block;
    list-style: none;
    cursor: pointer;
    border-top: 1px solid #fff;
    border-top: 1px solid #fff;
    background: $darkgreen;
    margin-bottom: 0;

    &:before {
        display: none;
    }

    &:hover a {
        color: #fff;
    }
}

.sidebar__links li a {
    width: 100%;
    color: rgba(255, 255, 255, 0.7);
    display: block;
    font-family: $arial;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    padding: 23px;
    text-transform: uppercase;
}

.sidebar__links .current_page_item a {
    color: #fff;
}

.js-active-sidebar {
    color: #fff !important;
}

// @-webkit-keyframes sidebarAnim {
//     0% {
//         position: relative;
//         top: 0;
//         margin-left: 0;
//     }
//     100% {
//         position: fixed;
//         top: 5%;
//         margin-left: 5%;
//     }
// }

@keyframes sidebarAnim {
    0% {
        position: relative;
        top: auto;
        margin-left: auto;
    }
    100% {
        position: fixed;
        top: 5%;
        margin-left: 5%;
    }
}

.sidebar__second-holder {
    margin-top: 25px;
    ul {
        li {
            width: 100%;
            display: block;
            list-style: none;
            cursor: pointer;
            border-top: 1px solid #fff;
            border-top: 1px solid #fff;
            background: $darkgreen;
            margin-bottom: 0; 

            &:before {
                display: none;
            }
                a {
                    font-family: $league;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-size: 20px;
                    color: #C6E0E4;
                    background: $darkgreen;
                    padding: 23px;
                    width: 100%; 
                    display: block;
                    transition: all .2s ease-in-out; 

                    &:hover {
                        color: $white;
                    }
                }
        }
    }
}